import React, { useState, useEffect, useContext } from 'react';
import { BgImage } from 'gbimage-bridge';
import Base from './article/base';
import Title from './article/title';
import Eyebrows from './complements/eyebrows';
import Container from './container';
import VideoModal from './videoModal';
import iconMd from '../images/icons/PLAY_HEAD_M.svg';
import iconLg from '../images/icons/PLAY_HEAD_LG.svg';
import iconSm from '../images/icons/PLAY_HEAD_SM.svg';
import { CompleteMastheadsFieldsFragment } from '../../graphql-types';
import VideoBackground from './video/videoBackground';
import MasterEyebrows from './masterEyebrows';
import PageType from '../context/pageType';
import PageTypeContext from '../context/pageTypeContext';
import ShareAnimatedButton from './article/shareAnimatedButton';
import Button from './button';

function customizedPageTypeEyebrows(pageType: PageType) {
  switch (pageType) {
    case PageType.Landing:
      return {
        refEyebrow1: '/',
        firstEyebrowClass: 'left-eyebrow-style orange-link eyebrow-text-second-format font-light',
        secondEyebrowClass: 'right-eyebrow-style eyebrow-text-second-format font-light',
      };
    case PageType.News:
      return {
        firstEyebrowClass: 'eyebrow-text-second-format font-bold',
      };
    default:
      return {};
  }
}

function customizedStylesForType(mastheadType?: string | null) {
  switch (mastheadType) {
    case 'big':
      return {
        titleClass: 'big-masthead-title',
        titleWrapper: 'mobile:pt-20 tablet:pt-24 mobile:pb-20 tablet:pb-25',
      };
    case 'simpler':
      return {};
    default:
      return {};
  }
}

function getColorsTheme(colorTheme?: string | null) {
  switch (colorTheme) {
    case 'Black':
      return {
        backgroundColor: 'bg-black',
        eyebrowsColor: 'text-white',
        titleColor: 'text-white',
        borderColor: 'border-black',
      };

    default:
    case 'White':
      return {
        backgroundColor: 'bg-white',
        eyebrowsColor: 'text-black',
        titleColor: 'text-black',
        borderColor: 'border-gray-300',
      };
  }
}
interface Props {
  mastheadData: CompleteMastheadsFieldsFragment['nodes'][0];
}

const Masthead: React.FC<Props> = ({ mastheadData }) => {
  const [eyebrowsParams, setEyebrowsParams] = useState({});
  const { pageType } = useContext(PageTypeContext);

  const backgroundImage =
    mastheadData.relationships?.field_image?.relationships?.field_media_image?.localFile
      ?.childImageSharp?.gatsbyImageData;
  const backgroundImageMobile =
    mastheadData.relationships?.field_mobile_image?.relationships?.field_media_image?.localFile
      ?.childImageSharp?.gatsbyImageData;

  useEffect(() => {
    setEyebrowsParams(customizedPageTypeEyebrows(pageType));
  }, [pageType]);

  const {
    title: caption,
    field_link: fieldLink,
    field_shareable: fieldShareable,
    field_button_title: buttonTitle,
    field_button_type: buttonType,
    field_email_content: emailContent,
    field_video_id: videoId,
    field_type: mastheadType,
    field_eyebrows: fieldEyebrows,
    field_custom_title: customTitle,
    field_masthead_color_theme: colorTheme,
    relationships,
  } = mastheadData;

  const customStyles = customizedStylesForType(mastheadType);
  const isStandardType = mastheadType === 'standard';

  const { backgroundColor, eyebrowsColor, titleColor, borderColor } = getColorsTheme(colorTheme);

  if (mastheadType === 'simpler') {
    const videoDesktop =
      relationships?.field_video?.relationships?.field_media_video_file?.localFile?.publicURL;
    const videoMobile =
      relationships?.field_mobile_video?.relationships?.field_media_video_file?.localFile
        ?.publicURL;
    if (relationships?.field_video?.relationships?.field_media_video_file?.localFile?.publicURL) {
      return (
        <VideoBackground
          eyebrow1={fieldEyebrows?.[0]}
          eyebrow2={fieldEyebrows?.[1]}
          videoMobile={videoMobile}
          video={videoDesktop}
          textColor="text-white"
          loop={pageType !== PageType.Project}
        />
      );
    }
    const desktopImage =
      relationships?.field_image?.relationships?.field_media_image?.localFile?.childImageSharp
        ?.gatsbyImageData;
    const mobileImage =
      relationships?.field_mobile_image?.relationships?.field_media_image?.localFile
        ?.childImageSharp?.gatsbyImageData;
    return (
      <MasterEyebrows
        eyebrow1={fieldEyebrows?.[0] ?? ''}
        eyebrow2={fieldEyebrows?.[1] ?? ''}
        backgroundImage={desktopImage}
        backgroundImageMobile={mobileImage}
        textColor="text-white"
      />
    );
  }
  const sources = backgroundImage
    ? backgroundImageMobile
      ? [
          backgroundImageMobile,
          {
            ...backgroundImage,
            'media': '(min-width: 1024px)',
          },
        ]
      : backgroundImage
    : null;

  const addTopBorder = (sources && !isStandardType) || !sources;

  const EyeBrowsMasthead = ({ showEyebrows = true } = {}) => (
    <Base className={sources ? '' : backgroundColor}>
      <div
        className={`${
          isStandardType ? '' : 'margin-lateral'
        } pt-5 laptop-standard:pt-13 mobile:pb-0 ${
          addTopBorder ? 'border-t-2' : ''
        } ${borderColor}`}
      >
        {showEyebrows && (
          <Eyebrows
            eyebrow1={fieldEyebrows?.[0] ?? caption ?? ''}
            eyebrow2={fieldEyebrows?.[1] ?? ''}
            firstEyebrowClass="eyebrow-text-second-format font-light"
            textColor={eyebrowsColor}
            {...eyebrowsParams}
          />
        )}
        {customTitle?.processed && (
          <Title
            title={customTitle?.processed}
            className={`mastheadTitle flex-col pt-6 ${titleColor} ${
              customStyles.titleWrapper ?? ''
            } ${customStyles.titleClass ?? ''}`}
          />
        )}
      </div>
      {fieldShareable && (
        <Container>
          <div className="padding-lateral flex laptop-standard:justify-end pb-12 shareButtonWrapper">
            <ShareAnimatedButton mailSubject={caption ?? ''} mailBody={emailContent ?? ''} />
          </div>
        </Container>
      )}
    </Base>
  );

  const CTAButton = buttonType && (
    <>
      {buttonType === 'video' && (
        <VideoModal
          type={'master'}
          iconLg={iconLg}
          iconMd={iconMd}
          iconSm={iconSm}
          buttonTitle={buttonTitle}
          videoId={videoId}
        />
      )}
      {(buttonType === 'internalLink' || buttonType === 'externalLink') && (
        <Button
          wrapperStyles="justify-center"
          styles="orangeOnHover"
          buttonTitle={buttonTitle}
          contentType={buttonType}
          internalLink={fieldLink}
          externalLink={fieldLink}
        />
      )}
    </>
  );

  if (isStandardType && sources) {
    return (
      <BgImage
        {...{ className: 'mastheadBackground mastheadStandard flex justify-center' }}
        image={sources}
        alt={relationships?.field_image?.field_media_image?.alt ?? ''}
        backgroundColor={'bg-orange-700'}
        preserveStackingContext
      >
        <div
          className="container absolute flex flex-col justify-center w-full"
          style={{ top: '10%' }}
        >
          {EyeBrowsMasthead({ showEyebrows: false })}
          {CTAButton}
        </div>
      </BgImage>
    );
  }

  return (
    <>
      {EyeBrowsMasthead()}
      <BgImage
        {...{ className: 'mastheadBackground' }}
        image={sources}
        alt={relationships?.field_image?.field_media_image?.alt ?? ''}
        backgroundColor={'bg-orange-700'}
        preserveStackingContext
      >
        <div className="absolute flex justify-center w-full" style={{ top: '60%' }}>
          {CTAButton}
        </div>
      </BgImage>
    </>
  );
};

export default Masthead;

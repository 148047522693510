import React, { LegacyRef } from 'react';
import { Maybe } from '../../../graphql-types';
import useWindowSize from '../../utils/useWindowSize';

interface Props {
  forwardedDesktopRef?: React.MutableRefObject<HTMLVideoElement | undefined>;
  forwardedMobileRef?: React.MutableRefObject<HTMLVideoElement | undefined>;
  video?: Maybe<string>;
  videoMobile?: Maybe<string>;
  backgroundImage?: Maybe<string>;
  onClick?: () => void;
  animated?: boolean;
  loop?: boolean;
  controls?: boolean;
  className?: string;
  videoClassName?: string;
}

interface VideoCompProps extends Props {
  type: 'desk' | 'mobile';
}

const VideoComp: React.FC<VideoCompProps> = (props) => {
  const className = 'w-full h-full ';
  const image = props.backgroundImage ?? '';
  const videoFile =
    props.type === 'desk' ? props.video ?? '' : props.videoMobile ?? props.video ?? '';
  const displayOption = props.type === 'desk' ? 'hidden tablet:block' : 'tablet:hidden';
  const forwardedRef = props.type === 'desk' ? props.forwardedDesktopRef : props.forwardedMobileRef;
  if (props.loop) {
    return (
      <video
        ref={forwardedRef as LegacyRef<HTMLVideoElement>}
        autoPlay
        muted
        playsInline
        loop
        className={
          `${props.videoClassName ?? ''} ${displayOption}` ?? `${className} ${displayOption}`
        }
        poster={image}
      >
        <source src={videoFile} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  } else if (props.controls) {
    return (
      <video
        ref={forwardedRef as LegacyRef<HTMLVideoElement>}
        controls
        className={
          `${props.videoClassName ?? ''} ${displayOption}` ?? `${className} ${displayOption}`
        }
        poster={image}
      >
        <source src={videoFile} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  }
  return (
    <video
      ref={forwardedRef as LegacyRef<HTMLVideoElement>}
      autoPlay
      muted
      playsInline
      className={
        `${props.videoClassName ?? ''} ${displayOption}` ?? `${className} ${displayOption}`
      }
    >
      <source src={videoFile} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

const RenderVideo: React.FC<Props> = (props) => {
  const { width } = useWindowSize();

  if (!width) {
    return null;
  }

  if (width > 640) {
    return (
      <div className={`overflow-hidden ${props.className ?? ''}`}>
        <div
          onClick={props.onClick}
          className={props.onClick ? 'cursor-pointer hidden tablet:block' : ''}
        >
          <VideoComp {...props} type="desk" />
        </div>
      </div>
    );
  }

  return (
    <div onClick={props.onClick} className={props.onClick && 'cursor-pointer tablet:hidden'}>
      <VideoComp {...props} type="mobile" />
    </div>
  );
};

export default RenderVideo;

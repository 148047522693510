import React, { Component, ReactNode } from 'react';

class Base extends Component<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> {
  render(): ReactNode {
    return <div {...this.props}>{this.props.children}</div>;
  }
}

export default Base;

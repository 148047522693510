import React from 'react';
import { Maybe } from '../../../graphql-types';

import Eyebrows from '../complements/eyebrows';
import RenderVideo from './renderVideo';

interface Props {
  video?: string | null;
  videoMobile?: string | null;
  eyebrow1?: Maybe<string>;
  eyebrow2?: Maybe<string>;
  textColor: string;
  loop?: boolean;
}

const VideoBackground: React.FC<Props> = (props) => {
  return (
    <div className="relative justify-center flex">
      <RenderVideo video={props.video} videoMobile={props.videoMobile} loop={props.loop} />
      <div className="absolute top-0 w-full ">
        <div className="container max-width padding-lateral pt-6 laptop-standard:pt-13">
          <Eyebrows
            eyebrow1={props.eyebrow1 ?? ''}
            eyebrow2={props.eyebrow2 ?? ''}
            textColor={props.textColor}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoBackground;

import React from 'react';

import PageType from './pageType';

export interface PageTypeInterface {
  pageType: PageType;
}
const PageTypeContext = React.createContext<PageTypeInterface>({
  pageType: PageType.Main,
});

export default PageTypeContext;
